/* pop-up msg*/
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
  z-index: 99999999999;
}

.popup {
  margin: 20% auto;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  width: 25%;
  position: relative;
  transition: all 5s ease-in-out;
}
.popup .content {
  max-height: 30%;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding-left: 5px;
  padding-bottom: 15px;
}
.popup .content div{
  float: left;
  width: 100%;
}
@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}

.btn-popup {
    color: #ffffff;
    background-color: #f37104;
    border-color: #ef5f08;
    width: 49%;
    margin-bottom: 5px;
    margin-right: 0px;
}

.btn-popup:hover,
.btn-popup:focus,
.btn-popup:active,
.btn-popup.active,
.open .dropdown-toggle.btn-popup {
  color: #ef5f08;
  background: transparent;
  border-color: #ef5f08;
}
.btn-popup:active,
.btn-popup.active,
.open .dropdown-toggle.btn-popup {
  background-image: none;
}
.btn-popup.disabled,
.btn-popup[disabled],
fieldset[disabled] .btn-popup,
.btn-popup.disabled:hover,
.btn-popup[disabled]:hover,
fieldset[disabled] .btn-popup:hover,
.btn-popup.disabled:focus,
.btn-popup[disabled]:focus,
fieldset[disabled] .btn-popup:focus,
.btn-popup.disabled:active,
.btn-popup[disabled]:active,
fieldset[disabled] .btn-popup:active,
.btn-popup.disabled.active,
.btn-popup[disabled].active,
fieldset[disabled] .btn-popup.active {
  background-color: #f37104;
  border-color: #ef5f08;
}

.btn-popup2 {
    color: #ffffff;
    background-color: #337ab7;
    border-color: #2e6da4;
    width: 49%;
    margin-bottom: 5px;
    margin-right: 0px;
}

.btn-popup2:hover,
.btn-popup2:focus,
.btn-popup2:active,
.btn-popup2.active,
.open .dropdown-toggle.btn-popup2 {
  color: #2e6da4;
  background: transparent;
  border-color: #2e6da4;
}
.btn-popup2:active,
.btn-popup2.active,
.open .dropdown-toggle.btn-popup2 {
  background-image: none;
}
.btn-popup2.disabled,
.btn-popup2[disabled],
fieldset[disabled] .btn-popup2,
.btn-popup2.disabled:hover,
.btn-popup2[disabled]:hover,
fieldset[disabled] .btn-popup2:hover,
.btn-popup2.disabled:focus,
.btn-popup2[disabled]:focus,
fieldset[disabled] .btn-popup2:focus,
.btn-popup2.disabled:active,
.btn-popup2[disabled]:active,
fieldset[disabled] .btn-popup2:active,
.btn-popup2.disabled.active,
.btn-popup2[disabled].active,
fieldset[disabled] .btn-popup2.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.input-group1 {
    margin-bottom: 0px;
}

.btn1 {
    margin-right: 0px;
}

.content1 {
    margin:0 2.5% 0 2.5%;
    overflow: hidden;
    padding: 8px;
    height:6.5vh;
    width:95%;
    color: #B1B1B1;
    border-bottom:1px solid #B1B1B1;
    text-align: center;
    font-size:18px;
}
